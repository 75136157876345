.logo {
  height: 32px;
  margin: 16px;
  /* background: rgba(255, 255, 255, 0.2); */
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 22px;
  color: #27a9e0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.logoIcon {
  /* width: 90px; */
  height: 32px;
  /* margin-right: 10px; */
}
.logoIconCosplay {
  width: 65px;
  height: 32px;
}
